
$header-height: 40px;
$footer-height: 25px;
$sidebar-width: 250px;

$panel-left-width: 100px;
$breadcrumb-height: $header-height;


html {
    position: relative;
}

html,
body {
    display: flex;
    height: 100%;
    margin: 0;
    min-width: 760px;
    width: 100%;
}

body > .app {
    background-color: $white;
    background-color: $background-color-primary;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    &.loadable:not(.loading) {
        opacity: 0;
    }

    &:not(.loadable):not(.loading) {
        opacity: 1;
        transition: opacity 0.5s;
    }

    > main {
        display: flex;
        flex: 1;
    }
}

.app-container {
    background-color: $background-color-primary;
    display: flex;
    flex: 1;

    .side-bar-container {
        flex: 0 0 auto;
    }

    .page-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 20px;

        .page-header-container {
            flex: 0 0 auto;
        }

        main {
            display: flex;
            flex: 1;
        }
    }
}

footer {
    background: $white;
    border-top: 1px solid $border-color;
    color: $black;
    font-size: 10px;
    padding: 0 8px;

    h1,
    h2 {
        display: inline-block;
        line-height: $footer-height;
    }

    h2::before {
        color: $darker-grey;
        content: ' / ';
    }

    .support {
        display: inline-block;
        line-height: $footer-height;

        i {
            font-size: 10px;
            &::before { padding-right: 10px; }
        }
    }
}
