* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    background: $body-background-color;
    color: $body-text-color;
    font-family: $font-stack;
    font-size: 16px;
}

html,
body {
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

button,
input,
select {
    color: $dark-grey;
    font-family: $font-stack;
    line-height: 1.5;
    outline: none;
}


select {
    &:hover {
        cursor: pointer;
    }
}

input,
select {
    color: $dark-grey;
    font-family: $font-stack;
    outline: none;
}

fieldset {
    border: 0 none;
}

a {
    color: inherit;
    text-decoration: none;
}

em {
    font-weight: 600;
}

label {
    display: block;
    line-height: 1.5;
}

input:focus,
textarea:focus {
    outline: none;
}

table {
    height: 100%;
}
