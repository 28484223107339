@import 'styles/typography';

$sidebar-width: 220px;
$sidebar-width-collapsed: 76px;

.side-bar-container {
    width: $sidebar-width;
    height: 100%;
    background-color: $background-color-secondary;
    border-right: 1px solid $border-color-primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;

    .side-bar-content {
        height: 100%;
        width: 100%;
        padding: 30px 16px 20px 16px;
        display: flex;
        flex-direction: column;

        .side-bar-item {
            height: 38px;
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;
            align-content: center;

            .bar-item {
                width: 100%;
                display: flex;
                cursor: pointer;
                border-radius: 6px;
                padding: 0 8px;

                &.selected {
                    background-color: $background-color-tertiary;

                    .bar-item-description {
                        .item-label {
                            color: $text-color-quaternary;
                        }
                        .item-count {
                            color: $white;
                            background-color: $text-color-quaternary;
                        }
                    }

                    svg {
                        fill: $text-color-quaternary;
                    }
                }

                &:not(.selected):hover {
                    background-color: $button-color-secondary;
                }
            }

            .bar-item-icon {
                height: 100%;
                width: 28px;
                display: flex;
                flex: 0 0 auto;
                align-items: center;
                justify-content: center;

                svg {
                    fill: $text-color-secondary;
                }
            }

            .bar-item-description {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 6px;

                .item-label {
                    flex: 0 1 auto;
                    color: $text-color-secondary;
                }

                .item-description-label {
                    font-size: 15px;
                }

                .item-count {
                    flex: 0 0 auto;
                    height: 18px;
                    min-width: 18px;
                    padding: 0 4px 1px 4px;
                    font-size: 10px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $background-color-secondary;
                    background-color: $text-color-secondary;
                }
            }

        }

    }

    .side-bar-footer {
        flex: 0 0 auto;
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .side-bar-expander {
            display: flex;
            padding: 0 26px;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            user-select: none;
            opacity: 0.7;
            color: $text-color-secondary;
            fill: $text-color-secondary;
            overflow: hidden;

            &:hover, &:active {
                opacity: 1;
                color: $text-color-quaternary;
                fill: $text-color-quaternary;
            }

            .side-bar-expander-icon {
                position: relative;
                top: 1px;
            }

            .bar-expander-title {
                width: 100%;
                flex: 0 1 auto;
                white-space: nowrap;
                display: flex;
                font-size: 14px;
                justify-content: center;
            }
        }

        .side-bar-footer-link {
            @include link-small-bold;
            height: 30px;
            color: $text-color-quaternary;
            cursor: pointer;
            display: flex;
            justify-content: center;
            display: none;
        }
    }

}

// sidebar animation
$sidebar-animation-speed: .3s;
.side-bar-container {
    // bring it out of document flow, for performance
    // position: absolute;
    // top: 0; left: 0; bottom: 0;
    // z-index: 1;

    transition: width $sidebar-animation-speed;

    .bar-expander-title .collapsible, .side-bar-header .collapsible {
        transition: max-width $sidebar-animation-speed,
            padding $sidebar-animation-speed,
            opacity $sidebar-animation-speed * 0.8;
        max-width: 100%;
    }

    .collapsible {
        transition: opacity $sidebar-animation-speed * 0.8;
        overflow: hidden;
        opacity: 1;
    }

    &.collapsed {
        width: $sidebar-width-collapsed;
        .collapsible {
            opacity: 0;
        }
        .bar-expander-title .collapsible, .side-bar-header .collapsible {
            max-width: 0px !important;
            padding: 0px !important;
        }
        .side-bar-expander-icon {
            transform: rotate(180deg) translateY(2px);
        }
    }
}
