@import 'styles/typography';
@import 'styles/themes';
@import 'styles/modules/loadable';

.view-organization-user-activate {
    width: 100%;
    height: 100%;
    background-color: $background-color-primary;
    display: flex;
    flex-direction: column;

    .state-active-description {
        margin-bottom: 25px;
    }

    .simple-header-container {
        height: 60px;
        width: 100%;
        flex: 0 0 auto;

        display: flex;
        align-items: center;
        padding: 0 20px;
        user-select: none;

        background-color: $background-color-primary;

        .header-logo {
            flex: 0 0 auto;
            opacity: 0.9;

            svg {
                fill: $black;
                width: 24px;
                height: 24px;
            }
        }

        .header-title {
            flex: 0 1 auto;
            padding-left: 8px;
            @include title-medium-large-uppercase;
            color: $black;
            font-weight: 300;
            white-space: nowrap;
        }
    }


    .organizations-user-activate-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 15%;

        input {
            border-radius: 6px;
        }

        .actions {
            margin-top: 70px;
            display: flex;
            justify-content: center;

            .button-activate {
                @include button-primary($black, $black);
                font-size: 13px;
                text-transform: uppercase;
            }
        }

        .page-error {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .state-critical-error,
            .state-expired-token,
            .contact-description {
                display: flex;
                flex-direction: column;
                justify-content: center;

                height: 50%;

                .state-error-icon {
                    height: 74px;
                    width: 100%;
                    margin-bottom: 26px;
                }
            }
        }

        .state-critical-error {

            .error-details {
                color: $error-color-tertiary;
            }

            h3 {
                margin-top: 50px;
            }
        }

        .state-activate {
            width: 100%;

            form {
                width: 40%;
                margin: auto;
                max-height: 100%;
                margin-top: 25px;
                margin-bottom: 50px;

                .fields .username .username-input {
                    background-color: $background-color-tertiary;
                }

                .fields input.error {
                    border-color: $border-color-secondary;
                }
            }

            .error-container {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                font-family: $font-stack;

                .error-message {
                    border-radius: 6px;
                    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
                        0 1px 10px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity), 0 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity);
                    min-width: 360px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    color: $white;
                    background-color: $error-color-secondary;
                    padding: 0 18px;
                    opacity: 90%;

                    .error-icon {
                        height: 100%;
                        width: 24px;
                        margin-right: 14px;
                        display: flex;
                        align-items: center;
                    }

                    .error-message-description {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        padding: 5px 0;
                        font-size: 13px;

                        .error-subtitle {
                            font-weight: 600;
                            border-bottom: 1px solid $white;
                        }

                        a,
                        a:hover {
                            border-color: $white;
                        }
                    }
                }
            }


        }

        .state-activate-success {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .success-icon {
                display: flex;
                justify-content: center;
                font-size: 120px;

                margin: 60px 0;
            }

            .success-content {
                height: 100%;
            }

            h1 {
                margin-bottom: 40px;
            }

            .counter {
                text-align: center;
                font-size: 48px;
                font-weight: 300;
                margin: 30px 0;
            }
        }

        h1 {
            font-size: 26px;
            font-weight: 300;
            text-align: center;
        }

        h2 {
            font-weight: 300;
            text-align: center;
            margin-top: 13px;
            line-height: 1.9;
        }

        h3 {
            font-weight: 300;
            text-align: center;
            margin: auto;
            margin-top: 13px;
            margin-bottom: 10px;
            line-height: 1.9;
        }

        pre {
            text-align: center;
            font-family: monospace;
        }

        a {
            font-weight: 600;
            border-bottom: 1px solid rgba(black, 0.2);

            &:hover {
                border-bottom: 1px solid rgba(black, 0.5);
            }
        }

        label {
            line-height: 2.4;
            margin-bottom: 20px;

            .label-text {
                color: $dark-grey;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                font-size: 12px;
                color: $mid-grey;
                text-align: left;
            }

            &:hover {
                input, select {
                    border-color: $border-color;
                }
            }

            input, select {
                display: block;
                width: 100%;
                font-size: 14px;
                padding: 10px 16px;
                border: 1px solid rgba($border-color, 0.6);
                background-color: $white
            }

            input[disabled] {
                background: none;
            }

            select {
                padding: 10px 10px;
                background: $white;
                height: 40px;
            }

            .error-text {
                float: right;
                position: relative;
                top: 16px;
                display: flex;
                align-items: center;

                .error-icon {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    svg {
                        fill: $error-color-secondary;
                        height: 12px;
                        width: 12px;
                    }
                }

                .error-label {
                    margin-left: 2px;
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 600;
                    height: 100%;
                    color: $error-color-secondary;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }
}
