$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

.snack-bar-container {
    height: 50px;
    position: absolute;
    top: 20px;
    left: calc(50% - 175px);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    @include title-medium-large;
    color: $white;

    z-index: 1;
    background-color: $background-color-primary;
    border-radius: 6px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
        0 1px 10px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
        0 2px 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity);

    &.error {
        background-color: $error-color-secondary;
    }

    &.success {
        background-color: $success-color-primary;
    }

    &.warn {
        background-color: $warn-color-primary;
        color: $black;
    }

    .message-container {
        display: flex;
        align-items: center;
        width: 100%;

        .message-icon {
            margin-right: 12px;
        }

        .message {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

}
