
// colors
// Be religious about not adding extra ones

$white: #fff;
$lighter-grey: #f9f9fa;
$light-grey: #c4ccce;
$mid-grey: #9eabaf;
$dark-grey: #73838e;
$darker-grey: #555d60;

$red: #a8294d;
$light-red: lighten($red, 15%);
$green: #31a341;
$light-green: lighten($green, 15%);
$dark-blue: #074269;
$blue: #2999ff;
$light-blue: lighten($blue, 15%);
$yellow: #ffd44a;
$light-yellow: lighten($yellow, 5%);
$light-back-yellow: lighten($yellow, 20%);
$navy: #242a33;
$light-navy: lighten($navy, 5%);
$black: #4b5163;

$body-text-color: #555;
$body-background-color: darken($lighter-grey, 2%);
$border-color: darken(#e9eaed, 3%);

// Correct Pallete
$background-color-primary: #f5f5f8;
$background-color-secondary: $white;
$background-color-tertiary: #e7ecfe;
$background-color-quaternary: lighten(#e7ecfe, 3%);

$background-error-color-primary: lighten(#ef5350, 8%);

$button-color-primary: #546ced;
$button-color-secondary: lighten(#e7ecfe, 3%);
$button-error-color-primary: lighten(#ef5350, 8%);

$text-color-primary: $black;
$text-color-secondary: darken(#bfc6d3, 20%);
$text-color-quaternary: #546ced;

$border-color-primary: #e4e4ea;
$border-color-secondary: #de350b;

$border: 1px solid $border-color-primary;

$error-color-primary: #de350b;
$error-color-secondary: darken(#de350b, 5%);
$error-color-tertiary: darken(#de350b, 10%);

$success-color-primary: #36b37e;

$warn-color-primary: #ffc400;

$shadow-key-umbra-opacity: 0.2 !default;
$shadow-key-penumbra-opacity: 0.14 !default;
$shadow-ambient-shadow-opacity: 0.12 !default;

// z-index

$z-application-header-menu: 11;
$z-grid-row: 10;
$z-modal: 13;
$z-overlay: 12;
$z-dropdown: 7;
$z-header: 6;
$z-elevated: 2;
$z-base: 1;
$z-hidden: 0;
$z-cloak: -1;

// typography

$font-stack: 'Open Sans', sans-serif;
$font-stack-monospace: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
