@mixin loadable($background-color: $white, $spinner: 'spinner.gif', $background-size: 50px) {
    // loading spinner
    &::after {

        background: $background-color url($spinner) no-repeat;
        background-position: center center;
        background-size: $background-size;
        bottom: 0;
        content: '';
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &.loading::after {
        display: block;
        transform: scale(1, 1);
    }

    &.loading {
        cursor: default;
        z-index: $z-elevated;
    }
}


.loadable {
    position: relative;

    @include loadable();
}
