@mixin title-medium-large {
    font-family: $font-stack;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
}

@mixin title-medium-large-uppercase {
    @include title-medium-large;
    text-transform: uppercase;
}

@mixin title-medium {
    font-family: $font-stack;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@mixin label-medium {
    font-family: $font-stack;
    font-size: 12px;
    font-weight: 600;
}

@mixin label-medium-bold {
    font-family: $font-stack;
    font-size: 12px;
    font-weight: 700;
}

@mixin link-small-bold {
    font-family: $font-stack;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@mixin label-error-small-bold {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
}
