
// Based on [Eric Meyer's reset 2.0](http://meyerweb.com/eric/tools/css/reset/index.html)
// and on modifications from [Compass](http://compass-style.org/)

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: 0;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

th,
td {
    font-weight: normal;
    text-align: left;
    vertical-align: middle;
}

* {

    // Making the box model not suck
    box-sizing: border-box;

    // Prevents link highlighting
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;

    // Prevent safari from resizing our text
    text-size-adjust: none;
    // Prevents the 'save image' dialog when a user holds a link or image
    -webkit-touch-callout: none;
}

a {
    text-decoration: none;
}

@media print {
    * {
        background: transparent !important;
        color: #000 !important;
        filter: none;
        text-shadow: none !important;
    }
}
