.svg-container {
    &.size-20 svg {
        height: 20px;
        width: 20px;
    }

    &.size-24 svg {
        height: 24px;
        width: 24px;
    }

    &.size-16 svg {
        height: 16px;
        width: 16px;
    }
}
