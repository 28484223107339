@import 'styles/typography';

.simple-header-container {
    height: 60px;
    width: 100%;

    display: flex;
    align-items: center;
    padding: 0 20px;

    background: $white;

    .header-logo {
        flex: 0 0 auto;
        opacity: 0.9;

        svg {
            fill: $black;
            width: 24px;
            height: 24px;
        }
    }

    .header-title {
        flex: 0 1 auto;
        @include title-medium-large-uppercase;
        padding-left: 8px;
        white-space: nowrap;
        color: $black;
    }
}


