@use '@fontsource/open-sans/scss/mixins.scss' as OpenSans;

$weights: 400, 300, 600, 700;
$styles: normal, italic;

@each $style in $styles {
    @each $weight in $weights {
        @include OpenSans.fontFace(
            $fontName: 'Open Sans',
            $display: fallback,
            $weight: $weight,
            $style: $style,
            $fontDir: '~@fontsource/open-sans/files'
        );
    }
}

.monospace,
.monospace * {
    font-family: $font-stack-monospace !important;
}
