.ag-grid {
    .ag-row {
        &:not(.ag-row-hover) {
            .icon-edit {
                visibility: hidden;
            }
        }

        &.ag-row-hover {
            .icon-edit {
                fill: $text-color-quaternary;
                visibility: visible;
            }

            .ag-cell.primary {
                text-decoration: underline;
                text-decoration-thickness: from-font;
                user-select: all;
            }
        }
    }
}
