@import 'styles/typography';
@import 'styles/themes';

@mixin position-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal.confirmation-modal-container {
    @include position-fixed;
    z-index: $z-modal;
    display: flex;
    padding-top: 150px;
    justify-content: center;

    .overlay {
        @include position-fixed;
        background: rgba($navy, 0.8);
        z-index: $z-overlay;
    }

    .confirmation-modal-content {
        z-index: $z-modal;
        width: 500px;
        height: 340px;
        background-color: $white;
        border-radius: 6px;
        box-shadow: 0px 10px 10px rgba($black, 0.06), 0px 2px 2px rgba($black, 0.08);

        display: flex;
        flex-direction: column;

        .header-container {
            flex: 0 0 auto;
            width: 100%;
            height: 55px;
            padding: 0 16px;
            border-bottom: 1px solid $border-color-primary;
            display: flex;
            justify-content: space-between;

            .header-logo-container {
                height: 100%;
                display: flex;
                align-items: center;

                svg {
                    height: 24px;
                    width: 24px;
                    fill: $black;
                }

                .header-title {
                    color: $black;
                    font-size: 16px;
                    margin-left: 12px;
                }
            }

            .close-icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                cursor: pointer;
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                }

                svg {
                    fill: $black;
                }
            }
        }

        .modal-main-content {
            flex: 1;
            padding-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .help-text-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .help-text-content {
                    height: 70px;
                    width: 90%;
                    display: flex;
                    padding: 8px;
                    background-color: $background-error-color-primary;
                    border-radius: 6px;
                    color: $white;

                    .info-icon {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 16px 0 8px;

                        svg {
                            fill: $white;
                        }
                    }

                    .confirmation-text-container {
                        height: 100%;
                        font-weight: 700;

                        .confirmation-text-title {
                            font-size: 14px;
                            margin-bottom: 8px;
                        }

                        .confirmation-text-sub-title {
                            font-size: 12px;

                            display: flex;
                            flex-direction: column;

                            > *:not(:last-child) {
                                margin-bottom: 8px;
                            }
                        }
                    }

                }
            }

            .confirmation-description-message {
                margin-top: 36px;
                font-size: 14px;
                color: $black;
            }

            form {
                height: 70px;
                width: 75%;
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: flex-end;

                input.error {
                    border-color: $error-color-primary;
                }

                .error-text {
                    display: flex;
                    justify-content: flex-end;
                    padding: 0 2px;
                    align-items: center;
                    width: 100%;
                    height: 24px;

                    .error-icon {
                        height: 100%;
                        display: flex;
                        align-items: center;

                        svg {
                            fill: $error-color-secondary;
                            height: 14px;
                            width: 14px;
                        }
                    }

                    .error-label {
                        @include label-error-small-bold;
                        margin-left: 2px;
                        height: 100%;
                        color: $error-color-secondary;
                        display: flex;
                        align-items: flex-end;
                    }
                }
            }
        }

        .modal-footer {
            flex: 0 0 auto;
            height: 70px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            padding-right: 20px;
            text-align: right;

            .footer-cancel {
                margin-right: 16px;
            }

            .footer-save {
                @include button-primary($button-error-color-primary, $button-error-color-primary);
            }
        }

        label {
            line-height: 2.4;
            width: 100%;

            &:hover {
                color: $mid-grey;
                input {
                    border-color: $light-grey;
                }
            }

            .error-text {
                color: $error-color-primary;
            }

            input {
                display: block;
                width: 100%;
                color: $black;
                font-size: 14px;
                padding: 10px 16px;
                border: $border;
                border-radius: 6px;
            }
        }
    }
}
